import {
    check_analytics_registration_cookie, try_send_GA_event,
    try_send_form_opened_GA_event,
} from "./analytics/analytics";
import { getGAUserID, getYaMetrikaUserID } from "./analytics/utils";
import { CopyToClipboard } from "./components/copy-to-clipboard";
import { initScrollWidthControl } from "./utils/scroll";

import { decodeHtmlentities } from "./utils/misc";
import { isEmailValid, isTextValid, isAmountValid } from "./utils/validation"

// load code for donation form
import './donation-form/form';
import './donation-form/amount';
import './donation-form/fields';


main();

function main() {
    // global helpers for Jinja templates
    window.decodeHtmlentities = decodeHtmlentities;
    window.isEmailValid = isEmailValid;
    window.isTextValid = isTextValid;
    window.isAmountValid = isAmountValid;

    // analytics
    check_analytics_registration_cookie();
    window.try_send_GA_event = try_send_GA_event;
    window.try_send_form_opened_GA_event = try_send_form_opened_GA_event;

    // global variable
    window.friendly = window.friendly || {};
    window.friendly.getGAUserID = getGAUserID;
    window.friendly.getYaMetrikaUserID = getYaMetrikaUserID;
    window.friendly.CopyToClipboard = CopyToClipboard;

    // misc
    initScrollWidthControl();
}
