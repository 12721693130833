/**
 * Copy to clipboard JS logic
 *
 * @link https://stackoverflow.com/questions/400212/how-do-i-copy-to-the-clipboard-in-javascript
 */

export function CopyToClipboard() {
    return {
        target: '',
        show_message: false,
        show_button: true,

        init: function () {
            this.target = this.$refs.target.innerText || this.$refs.target.textContent;
        },

        copyAction: function () {
            const _ = this;

            _.show_button = false;
            _.copyTextToClipboard(_.target);
            _.show_message = true;

            setTimeout(() => {
                _.show_button = true;
                _.show_message = false;
            }, 1500);

            return false;
        },

        copyTextToClipboard: function (text) {
            if (!navigator.clipboard) {
                this.fallbackCopyTextToClipboard(text);
                return;
            }
            navigator.clipboard.writeText(text).then(function () {
                console.log('Async: Copying to clipboard was successful!');
            }, function (err) {
                console.error('Async: Could not copy text: ', err);
            });
        },

        fallbackCopyTextToClipboard: function (text) {
            const textArea = document.createElement("textarea");
            textArea.value = text;

            // Avoid scrolling to bottom
            textArea.style.top = "0";
            textArea.style.left = "0";
            textArea.style.position = "fixed";

            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();

            try {
                const successful = document.execCommand('copy');
                const msg = successful ? 'successful' : 'unsuccessful';
                console.log('Fallback: Copying text command was ' + msg);
            } catch (err) {
                console.error('Fallback: Oops, unable to copy', err);
            }

            document.body.removeChild(textArea);
        }
    }
}
